import React, { useContext, useEffect, useState } from 'react';
import { theme } from '@konecorp/ui-library';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

import SubHeader from '../SubHeader';
import InstallationCard from '../InstallationCard';
import Context from '../../context';
import { InstallationCardData, Installation } from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';

const useStyles = makeStyles(() => ({
  content: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(3),
    overflow: 'auto',
  },
}));

const CompletedInstallationsList = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { completedInstallations, updateIsLoading } = useContext(Context);

  const history = useHistory();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);

  const [showDiaglog, setShowDiaglog] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!completedInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, [completedInstallations]);

  useEffect(() => {
    (async () => {
      if (completedInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = completedInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const completedInstallationCardData = await Promise.all(promises);
        setInstallationCardData(
          completedInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [completedInstallations]);

  const handleCardClick = (installationCardData: InstallationCardData) => {
    const { network } = installationCardData;
    window.sessionStorage.setItem('previousPageInternalUrl', '/completed');

    history.push(`${network.networkNumber}/deviations`);
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.completedInstallations')}
        handleGoBackClick={() => {
          history.push('/');
        }}
      />

      <Dialog data-testid="not-allowed" open={showDiaglog}>
        <>
          <DialogContent>
            <DialogContentText data-testid="alert-dialog-description">
              {t('myInstallation.noAccess')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              data-testid="button-alert-ok"
              onClick={() => setShowDiaglog(false)}
            >
              {t('myInstallation.ok')}
            </Button>
          </DialogActions>
        </>
      </Dialog>

      {installationCardData && (
        <div className={classes.content} aria-label="all-installations">
          {installationCardData.map(
            (installationCardData: InstallationCardData, index: number) => {
              const { network, deviations, installation } = installationCardData;

              return (
                <InstallationCard
                  key={`all-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => handleCardClick(installationCardData)}
                />
              );
            }
          )}
        </div>
      )}
    </>
  );
};

export default CompletedInstallationsList;
