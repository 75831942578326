import React, { useContext, useEffect, useState } from 'react';
import { theme } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SubHeader from '../../components/SubHeader';
import InstallationCard from '../InstallationCard';
import Context from '../../context';
import {
  ActivityDifferentiator,
  InstallationCardData,
  Installation,
} from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useGetToken } from '../../hooks/useGetToken';
import { useGetUserData } from '../../hooks/useGetUserData';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      overflow: 'auto',
      paddingTop: theme.spacing(3),
    },
  })
);

const OngoingInstallationsList = (): JSX.Element => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [getTokenFunction] = useGetToken();
  const [employeeId] = useGetUserData();
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);

  const { ongoingInstallations, updateIsLoading } = useContext(Context);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (!ongoingInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (ongoingInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = ongoingInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const ongoinInstallationCardData = await Promise.all(promises);

        setInstallationCardData(
          ongoinInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [ongoingInstallations]);

  const handleCardClick = (installationCardData: InstallationCardData) => {
    const { network, installation } = installationCardData;
    const role = getKoneEmployeeUserRole({
      installationData: installation as Installation,
      employeeId,
    });

    window.sessionStorage.setItem('previousPageInternalUrl', '/ongoing');

    const path =
      role === ActivityDifferentiator.SPV
        ? 'progress'
        : !installation.isModelData
        ? 'myday'
        : 'execution';
    history.push(`${network.networkNumber}/${path}`);
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.ongoingInstallations')}
        handleGoBackClick={() => {
          history.push('/');
        }}
      />
      {installationCardData && (
        <>
          <div className={classes.content}>
            {installationCardData.map(
              (installationCardData: InstallationCardData, index: number) => {
                const { network, deviations, installation } = installationCardData;
                return (
                  <InstallationCard
                    key={`ongoing-installation-card-${index}`}
                    network={network}
                    deviations={deviations}
                    status={installation.status}
                    role={getKoneEmployeeUserRole({
                      installationData: installation as Installation,
                      employeeId,
                    })}
                    firstAssignmentDate={installation.firstAssignmentDate}
                    latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                    hasQualityForm={installation.hasQualityForm}
                    onClick={() => handleCardClick(installationCardData)}
                  />
                );
              }
            )}
          </div>
        </>
      )}
    </>
  );
};

export default OngoingInstallationsList;
