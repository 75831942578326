/*istanbul ignore file*/
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Box, Typography, TextField, MenuItem } from '@material-ui/core';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { InstallationStatus, Scenario, ActivityDifferentiator } from '../../schemas';

const useStyles = makeStyles(() => ({
  textField: {
    maxWidth: '300px',
  },
  generationFrom: {
    border: '1px solid',
    padding: '30px',
    marginTop: '20px',
  },
}));

type GenerateNetworkFormProps = {
  onSubmit: (formState: FormState) => Promise<string>;
};

export type FormState = {
  supervisorEmployeeID: string;
  delegatedSupervisorEmployeeID?: string;
  installerEmployeeID: string;
  testerEmployeeID: string;
  desiredState:
    | InstallationStatus.TO_BE_STARTED
    | InstallationStatus.FOR_INSTALLER_ACCEPTANCE
    | InstallationStatus.FOR_TESTER_ACCEPTANCE
    | InstallationStatus.FOR_FINAL_INSPECTION
    | InstallationStatus.SEB_ACCEPTED;
  countryKey: 'FI' | 'SE' | 'GB';
  scenario: Scenario;
  commissioningBy: ActivityDifferentiator.INST | ActivityDifferentiator.CMSN;
  tacoData: '';
  modelData: 'withModelData' | 'withoutModelData';
};

const countryOptions = [
  { displayText: 'FI', value: 'FI' },
  { displayText: 'GB', value: 'GB' },
  { displayText: 'SE', value: 'SE' },
];

const scenarioOptions = [
  {
    displayText: 'Installer - Tester - Supervisor',
    value: Scenario.INSTALLER_TESTER_SUPERVISOR,
  },
  {
    displayText: 'Installer - Supervisor',
    value: Scenario.INSTALLER_SUPERVISOR,
  },
];

const commissioningByOptions = [
  {
    displayText: 'Installer',
    value: ActivityDifferentiator.INST,
  },
  {
    displayText: 'Tester/Supervisor',
    value: ActivityDifferentiator.CMSN,
  },
];

const tacoDataOptions = [
  {
    displayText: 'With Taco Data',
    value: 'withTacoData',
  },
  {
    displayText: 'Without Taco Data',
    value: 'withoutTacoData',
  },
];
const modelDataOptions = [
  {
    displayText: 'With Model Data',
    value: 'withModelData',
  },
  {
    displayText: 'Without Model Data',
    value: 'withoutModelData',
  },
];
enum MessageType {
  NONE,
  FORM_MISSING,
  ERROR,
  SUCCESS,
}

const getMessageStyle = (messageType: MessageType): React.CSSProperties => {
  switch (messageType) {
    case MessageType.FORM_MISSING:
    case MessageType.ERROR:
      return { color: 'red' };
    case MessageType.SUCCESS:
      return { color: 'green' };
    default:
      return {};
  }
};

const getMessageText = (messageType: MessageType, networkNumber?: string): string => {
  switch (messageType) {
    case MessageType.FORM_MISSING:
      return 'Please fill in all required info';
    case MessageType.SUCCESS:
      return `Generate network success !! Created network number: ${networkNumber}`;
    case MessageType.ERROR:
      return 'Generate network fail :(';
    default:
      return '';
  }
};

const DemoGenerateNetworkForm = (props: GenerateNetworkFormProps): JSX.Element => {
  const classes = useStyles();

  const [formState, setFormState] = useState<FormState>({
    supervisorEmployeeID: '',
    installerEmployeeID: '',
    testerEmployeeID: '',
    desiredState: InstallationStatus.TO_BE_STARTED,
    countryKey: 'FI',
    scenario: Scenario.INSTALLER_TESTER_SUPERVISOR,
    commissioningBy: ActivityDifferentiator.INST,
    tacoData: '',
    modelData: 'withModelData',
  });
  const [messageState, setMessageState] = useState<MessageType>(MessageType.NONE);
  const [createdNetworkState, setCreatedNetworkStated] = useState<string>('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const onSubmitClick = async (): Promise<void> => {
    setMessageState(MessageType.NONE);
    setCreatedNetworkStated('');

    const formToSubmit = {
      ...formState,
      delegatedSupervisorEmployeeID: formState.delegatedSupervisorEmployeeID || undefined,
    };

    const keysToSkip =
      formToSubmit.scenario === Scenario.INSTALLER_SUPERVISOR
        ? ['delegatedSupervisorEmployeeID', 'testerEmployeeID']
        : ['delegatedSupervisorEmployeeID'];

    const entriesToCheck = Object.entries(formToSubmit).filter(
      ([key]) => !keysToSkip.includes(key)
    );

    const hasAllInfo = entriesToCheck.every(([_key, value]) => Boolean(value));

    if (!hasAllInfo) {
      setMessageState(MessageType.FORM_MISSING);
      return;
    }

    const newNetworkNumber = await props.onSubmit(formToSubmit);
    if (newNetworkNumber) {
      setCreatedNetworkStated(newNetworkNumber);
      setMessageState(MessageType.SUCCESS);
    } else {
      setMessageState(MessageType.ERROR);
    }
  };

  return (
    <Box className={classes.generationFrom}>
      <Typography variant="h4">Network Generation Form</Typography>
      {messageState !== MessageType.NONE && (
        <Typography style={getMessageStyle(messageState)} variant="subtitle1">
          {getMessageText(messageState, createdNetworkState)}
        </Typography>
      )}

      <Box mt={3}>
        <TextField
          fullWidth
          required
          id="supervior-employee-id"
          label="Supervisior Employee ID"
          name="supervisorEmployeeID"
          variant="outlined"
          className={classes.textField}
          onChange={handleInputChange}
          value={formState?.supervisorEmployeeID}
        ></TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          id="delegated-supervior-employee-id"
          label="Delegated Supervisior Employee ID"
          name="delegatedSupervisorEmployeeID"
          variant="outlined"
          className={classes.textField}
          onChange={handleInputChange}
          value={formState?.delegatedSupervisorEmployeeID}
        ></TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required
          id="installer-employee-id"
          label="Installer Employee ID"
          name="installerEmployeeID"
          variant="outlined"
          className={classes.textField}
          onChange={handleInputChange}
          value={formState?.installerEmployeeID}
        ></TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required={formState.scenario === Scenario.INSTALLER_TESTER_SUPERVISOR}
          id="tester-employee-id"
          label="Tester Employee ID"
          name="testerEmployeeID"
          variant="outlined"
          onChange={handleInputChange}
          className={classes.textField}
          value={formState?.testerEmployeeID}
        ></TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Desired State"
          name="desiredState"
          variant="outlined"
          select
          className={classes.textField}
          onChange={handleInputChange}
          defaultValue={InstallationStatus.TO_BE_STARTED}
          value={formState.desiredState}
        >
          {[
            InstallationStatus.TO_BE_STARTED,
            InstallationStatus.FOR_INSTALLER_ACCEPTANCE,
            InstallationStatus.FOR_TESTER_ACCEPTANCE,
            InstallationStatus.FOR_FINAL_INSPECTION,
            InstallationStatus.SEB_ACCEPTED,
          ].map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Country"
          name="countryKey"
          variant="outlined"
          select
          className={classes.textField}
          onChange={handleInputChange}
          defaultValue={'fi'}
          value={formState.countryKey}
        >
          {countryOptions.map(({ displayText, value }) => (
            <MenuItem key={value} value={value}>
              {displayText}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Scenario"
          name="scenario"
          variant="outlined"
          select
          className={classes.textField}
          onChange={handleInputChange}
          defaultValue={Scenario.INSTALLER_TESTER_SUPERVISOR}
          value={formState.scenario}
        >
          {scenarioOptions.map(({ displayText, value }) => (
            <MenuItem key={value} value={value}>
              {displayText}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Commissioning By"
          name="commissioningBy"
          variant="outlined"
          select
          className={classes.textField}
          onChange={handleInputChange}
          defaultValue={ActivityDifferentiator.INST}
          value={formState.commissioningBy}
        >
          {commissioningByOptions.map(({ displayText, value }) => (
            <MenuItem key={value} value={value}>
              {displayText}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Model Data"
          name="modelData"
          variant="outlined"
          select
          className={classes.textField}
          onChange={handleInputChange}
          defaultValue={'withModelData'}
          value={formState.modelData}
        >
          {modelDataOptions.map(({ displayText, value }) => (
            <MenuItem key={value} value={value} onClick={() => (formState.tacoData = '')}>
              {displayText}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Box mt={3}>
        <TextField
          fullWidth
          required
          label="Taco Data"
          name="tacoData"
          variant="outlined"
          select
          className={classes.textField}
          onChange={handleInputChange}
          value={formState.tacoData}
        >
          {tacoDataOptions.map(({ displayText, value }) => (
            <MenuItem
              key={value}
              value={value}
              disabled={
                formState.modelData === 'withoutModelData' && value === 'withTacoData'
              }
            >
              {displayText}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box mt={4}>
        <Button
          variant="outlined"
          endIcon={<PlaylistAddIcon />}
          size="large"
          color="primary"
          onClick={onSubmitClick}
        >
          Generate new network
        </Button>
      </Box>
    </Box>
  );
};

export default DemoGenerateNetworkForm;
