import React, { useEffect, useContext, useState } from 'react';
import { theme } from '@konecorp/ui-library';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import InstallationCard from '../InstallationCard';
import SubHeader from '../../components/SubHeader';
import Context from '../../context';
import {
  ActivityDifferentiator,
  InstallationCardData,
  Installation,
} from '../../schemas';
import { useUpdateInstallationListsInContext } from '../../hooks/useUpdateInstallationListsInContext';
import { useIfSubcontractor } from '../../hooks/useIfSubcontractor';
import { useGetToken } from '../../hooks/useGetToken';
import {
  getInstallationCardData,
  sortInstallationsCardData,
} from '../../helpers/getInstallationLists';
import { getKoneEmployeeUserRole } from '../../helpers/getUserRole';
import { useGetUserData } from '../../hooks/useGetUserData';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      overflow: 'auto',
      paddingTop: theme.spacing(3),
    },
  })
);

const ToBeStartedInstallationsList = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [updateInstallationListsInContext] = useUpdateInstallationListsInContext();
  const [isSubcontractor] = useIfSubcontractor();
  const [employeeId] = useGetUserData();
  const [getTokenFunction] = useGetToken();
  const [installationCardData, setInstallationCardData] = useState<
    InstallationCardData[]
  >([]);

  const { toBeStartedInstallations, updateIsLoading } = useContext(Context);

  useEffect(() => {
    (async () => {
      if (!toBeStartedInstallations) {
        try {
          updateIsLoading(true);
          await updateInstallationListsInContext();
        } catch (e) {
          console.error('error while fetching installation list', e);
        } finally {
          updateIsLoading(false);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (toBeStartedInstallations && installationCardData.length === 0) {
        updateIsLoading(true);

        const token = await getTokenFunction();
        const promises = toBeStartedInstallations.map((network) =>
          getInstallationCardData(network, token)
        );

        const toBeStartedInstallationCardData = await Promise.all(promises);
        setInstallationCardData(
          toBeStartedInstallationCardData.sort(sortInstallationsCardData)
        );
        updateIsLoading(false);
      }
    })();
  }, [toBeStartedInstallations]);

  const onInstallationCardClick = (installationData: InstallationCardData) => {
    const releaseNetwork = !(
      installationData.network.isModelData && !installationData.network.isTacoDataQuest
    );
    if (releaseNetwork) {
      // If no taco data no need to start installation
      window.sessionStorage.setItem('previousPageInternalUrl', '/tobestarted');

      const userRole = getKoneEmployeeUserRole({
        installationData: installationData.installation as Installation,
        employeeId,
      });

      if (userRole === ActivityDifferentiator.SPV) {
        history.push(
          `${installationData.network.networkNumber}/newinstallation?role=${ActivityDifferentiator.SPV}`
        );
        return;
      }

      const rootPath = isSubcontractor ? '/subcontractor' : '';
      history.push(`${rootPath}/${installationData.network.networkNumber}/starting`);
    }
  };

  return (
    <>
      <SubHeader
        title={t('myInstallation.toBeStartedInstallations')}
        handleGoBackClick={() => {
          history.push('/');
        }}
      />
      <div className={classes.content}>
        {installationCardData &&
          installationCardData.map(
            (installationData: InstallationCardData, index: number) => {
              const { network, installation, deviations } = installationData;

              return (
                <InstallationCard
                  key={`tobestarted-installation-card-${index}`}
                  network={network}
                  deviations={deviations}
                  status={installation.status}
                  role={getKoneEmployeeUserRole({
                    installationData: installation as Installation,
                    employeeId,
                  })}
                  firstAssignmentDate={installation.firstAssignmentDate}
                  latestAnsweredQdQuestionSet={installation.latestAnsweredQdQuestionSet}
                  hasQualityForm={installation.hasQualityForm}
                  onClick={() => onInstallationCardClick(installationData)}
                />
              );
            }
          )}
      </div>
    </>
  );
};

export default ToBeStartedInstallationsList;
